export class FilterData {
	static getDateType() {
		return [
			{
				name: 'Touchpoint Date',
				value: 'touch_point',
			},
			{
				name: 'Created Date',
				value: 'created_date_utc',
			},
			{
				name: 'Closed Date',
				value: 'close_date',
			},
		];
	}

	static getConnectors() {
		return [
			{
				name: 'All',
				value: 'all',
			},

			{
				name: 'Facebook',
				value: 'facebook',
			},
			{
				name: 'LinkedIn',
				value: 'linkedin',
			},
			{
				name: 'Instagram',
				value: 'instagram',
			},
			{
				name: 'Youtube',
				value: 'youtube',
			},
		];
	}
	static getAttributionModel() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_creation_touch',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non-direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path_model',
			},
			// {
			//   name: 'Data-driven attribution',
			//   value: 'markov',
			// },
		];
	}
	static getAttributionModelforPCT() {
		return [
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},

			{
				name: 'Linear',
				value: 'linear_model',
			},
		];
	}

	static getAttributionModelForSEO() {
		return [
			{
				name: 'Linear',
				value: 'linear_model',
			},
		];
	}

	static getAttributionModelForSmd() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_create',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_touch',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non_direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path',
			},
		];
	}

	static getAttributionModelComparison() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_creation_touch',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non-direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path_model',
			},
			{
				name: 'Data-driven attribution 1',
				value: 'markov',
			},
			{
				name: 'Data-driven attribution 2',
				value: 'shapley',
			},
			// {
			//   name: 'Data-driven attribution 3',
			//   value: 'occurrence',
			// },
		];
	}

	static getPMTAttributionModel() {
		return [
			{
				name: 'Lead Creation Touch',
				value: 'lead_creation_touch',
			},
			{
				name: 'First Touch',
				value: 'first_touch',
			},
			{
				name: 'Last Interaction',
				value: 'last_interaction',
			},
			{
				name: 'Last Non-Direct',
				value: 'last_non-direct',
			},
			{
				name: 'Linear',
				value: 'linear_model',
			},
			{
				name: 'U-Shaped',
				value: 'u_shaped',
			},
			{
				name: 'W-Shaped',
				value: 'w_shaped',
			},
			{
				name: 'Full Path Model',
				value: 'full_path_model',
			},
			// {
			//   name: 'Data-driven attribution',
			//   value: 'markov',
			// },
		];
	}

	static getDimensionParameter() {
		return [
			{
				name: 'Channel',
				value: 'channel',
			},
			{
				name: 'Source',
				value: 'source',
			},
			{
				name: 'Campaign',
				value: 'campaign',
			},
			{
				name: 'Ad Group',
				value: 'ad_group',
			},
			{
				name: 'Keyword',
				value: 'keyword',
			},
		];
	}

	static getDimensionParameterforPCt() {
		return [
			{
				name: 'Channel',
				value: 'channel',
			},
			{
				name: 'Source',
				value: 'source',
			},
			{
				name: 'Campaign',
				value: 'campaign',
			},
		];
	}

	static getOperatorList() {
		return [
			{
				name: 'Containing',
				value: 'contains',
			},
			{
				name: 'Exactly matching',
				value: 'equals',
			},
			{
				name: 'Starts with',
				value: 'starts_with',
			},
			{
				name: 'Ends with',
				value: 'ends_with',
			},
		];
	}

	static getMediumLabels = () => [
		{
			name: 'Channel',
			value: 'channel',
		},
		{
			name: 'Source',
			value: 'source',
		},
		{
			name: 'Campaign Name',
			value: 'campaign',
		},
		{
			name: 'AdGroup',
			value: 'AdGroup',
			disabled: true,
		},
		{
			name: 'Keyword',
			value: 'keyword',
			disabled: true,
		},
	];

	static getChannelPerformanceMedium = () => [
		{
			name: 'Channel',
			value: 'channel',
		},
		{
			name: 'Source',
			value: 'source',
		},
	];

	static getTopConversionMedium = () => [
		{
			name: 'Channel',
			value: 'channel',
		},
	];

	static hubspotEmailFilter = () => [
		{
			name: 'demoMail.com',
			value: 'demo',
		},
		{
			name: 'test@here.com',
			value: 'test',
		},
	];

	static hubspotNameFilter = () => [
		{
			name: 'Demo',
			value: 'demo',
		},
		{
			name: 'Tester',
			value: 'test',
		},
	];
}
