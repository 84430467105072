import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, { useEffect, useState } from 'react';

interface LineChartProps {
	subtitle?: string;
	title?: string;
	xAxisCategories?: any;
	primaryYAxisTitle?: string;
	secondaryYAxisTitle?: string;
	colors?: string[];
	primaryYAxisName?: string;
	secondaryYAxisName?: string;
	primaryYAxisType?: string;
	secondaryYAxisType?: string;
	primaryYAxisData?: any[];
	secondaryYAxisData?: any[];
	primaryYAxisFormat?: string;
	secondaryYAxisFormat?: string;
	primaryAxisSuffix?: string;
	secondaryAxisSuffix?: string;
	showPrimaryAxisMarker?: boolean;
	showSecondaryAxisMarker?: boolean;
	showSecondaryAxisLegend?: boolean;
	showPrimaryAxisLegend?: boolean;
	xAxisTitle?: string;
	height?: number;
	minHeight?: any;
	backgroundColor?: string;
	multilineChart?: boolean;
	showHash?: boolean;
	fromInsights?: boolean;
}

const LineChart: React.FC<LineChartProps> = (props: any) => {
	NoDataToDisplay(Highcharts);
	const theme: any = useTheme();

	const [options, setOptions]: any = useState({});
	const [isLoading, setIsLoading]: any = useState(true);
	const isScientificNotation = (value: any) => {
		const scientificNotationPattern =
			/^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
		return scientificNotationPattern.test(value.toString());
	};

	useEffect(() => {
		if (
			props.secondaryYAxisData?.length > 0 ||
			props.primaryYAxisData?.length > 0
		) {
			const tempOptions = {
				chart: {
					plotBackgroundColor:
						theme.palette.mode === 'dark' && props.backgroundColor
							? props.backgroundColor
							: theme.palette.background.light,
					backgroundColor:
						theme.palette.mode === 'dark' && props.backgroundColor
							? props.backgroundColor
							: theme.palette.background.light,
					borderColor:
						theme.palette.mode === 'dark' && props.backgroundColor
							? props.backgroundColor
							: theme.palette.background.light,
					height: props.height,
					width: props.width,
					className: 'highcharts-color-0',
					style: {
						fontFamily: 'Lato',
						textTransform: 'capitalize',
						fontSize: '14px !important',
						color: '#707070',
					},
					plotBorderWidth: null,
					plotShadow: false,
				},
				title: {
					text: props.title || '',
					style: {
						color: theme.palette.text.primary,
					},
				},

				subtitle: {
					text: props.subtitle || '',
				},

				credits: {
					enabled: false,
				},

				xAxis: {
					categories: props.xAxisCategories || [],
					crosshair: true,
					title: {
						text: props.xAxisTitle || '',
						style: {
							color: theme.palette.text.primary,
						},
					},
					labels: {
						style: {
							color: theme.palette.text.primary,
						},
					},
				},

				legend: {
					layout: 'horizontal',
					align: 'center',
					verticalAlign: 'bottom',
					itemMarginBottom: 10,
					itemStyle: {
						fontSize: '13px',
						color: theme.palette.text.primary,
						fontWeight: '400 !important',
					},
					itemHoverStyle: {
						color: '#707070',
					},
					itemHiddenStyle: {
						color: '#707070',
					},
				},
				lang: {
					noData: props.noDataMessage || 'There is no data in the chart',
				},

				yAxis: [
					{
						// Primary yAxis
						labels: {
							format: props.primaryYAxisFormat || '',
							style: {
								// color: '#707070',
								color: theme.palette.text.primary,
							},
						},
						title: {
							text: props.primaryYAxisTitle || '',
							style: {
								color: theme.palette.text.primary,
							},
						},
						gridLineWidth: theme.palette.mode === 'dark' ? 0.2 : 1,
					},
					{
						// Secondary yAxis
						title: {
							text: props.secondaryYAxisTitle || '',
							style: {
								color: theme.palette.text.primary,
							},
						},
						labels: {
							format: props.secondaryYAxisFormat || '',
							style: {
								// color: '#707070',
								color: theme.palette.text.primary,
							},
						},
						opposite: true,
						gridLineWidth: theme.palette.mode === 'dark' ? 0.2 : 1,
					},
				],
				series: props.multilineChart
					? props.secondaryYAxisData
					: [
							{
								showInLegend: !!props.showPrimaryAxisLegend,
								name: props.primaryYAxisName || 'Ad Impressions',
								type: props.primaryYAxisType || 'spline',
								yAxis: 1,
								data: props.primaryYAxisData || [],
								marker: {
									enabled: !!props.showPrimaryAxisMarker,
								},
								tooltip: {
									pointFormat: props.fromInsights
										? props.showHash
											? '<b>#{point.y:.2f}</b>'
											: '<b>${point.y:.2f}</b>'
										: props.primaryYAxisName == 'CTR'
										? '<b>{point.y:.2f}%</b>'
										: '<b>{point.y:.2f}</b>',
									valueSuffix: props.primaryAxisSuffix || '',
								},
							},
							{
								showInLegend: !!props.showSecondaryAxisLegend,
								name: props.secondaryYAxisName || 'Ad Clicks',
								type: props.secondaryYAxisType || 'spline',
								data: props.secondaryYAxisData || [],
								marker: {
									enabled: !!props.showSecondaryAxisMarker,
								},
								tooltip: {
									pointFormat: props.fromInsights
										? props.showHash
											? '<b>#{point.y:.2f}</b>'
											: '<b>${point.y:.2f}</b>'
										: props.primaryYAxisName == 'CTR'
										? '<b>$ {point.y:.2f}</b>'
										: '<b>{point.y:.2f}</b>',
									valueSuffix: props.secondaryAxisSuffix || '',
								},
							},
					  ],
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 500,
							},
							chartOptions: {
								legend: {
									layout: 'horizontal',
									align: 'center',
									verticalAlign: 'bottom',
								},
							},
						},
					],
				},
				colors: theme.palette.graph,
			};
			setOptions({ ...tempOptions });
			setIsLoading(false);
		}
	}, [props.secondaryYAxisData, props.primaryYAxisData]);

	return (
		<>
			{!isLoading ? (
				<Grid
					item
					style={{ minHeight: props.minHeight ? props.minHeight : '400px' }}
					xs={12}
					mt={2}
				>
					<HighchartsReact highcharts={Highcharts} options={options} />
				</Grid>
			) : null}
		</>
	);
};

export default LineChart;
