import {
	Autocomplete,
	Chip,
	FormControlLabel,
	Grid,
	Link,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './ShareReports.scss';
import Button from '../../components/Button/Button';
import useLocalStorage from '../../utils/localStorage';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import useApiService from '../../services/api.service';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';

function ShareThroughEmail({
	dateRange,
	filters,
	toaster,
	loader,
	name,
	id,
	shareReportsRequest,
	exportPDFWithComponent,
	closePopup,
	getEmail,
	downloadPDFClassName,
}: any) {
	const includeCSV = [
		'CMO',
		'Performance',
		'Channel Performance',
		'Paid Campaign Tracker',
		'Paid Media Tracker',
		'SEO',
		'Email Marketing',
		'Total Spend',
	];
	const APIService: any = useApiService();
	const userData: any = useLocalStorage.getItem('userData');
	const { control, handleSubmit, setValue }: any = useForm();
	const [values, setValues] = React.useState('CSV');
	const [showScheduling, setShowScheduling] = useState(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues((event.target as HTMLInputElement).value);
	};
	const [chipValues, setChipValues]: any = useState();
	const deletedAttributes: any = useRef([]);
	const addedAttributes: any = useRef([]);

	const [currentChipIndex, setCurrentChipIndex]: any = useState(-1);
	const [chipsList, setChipsList]: any = useState([]);

	const [textAreaValCopy, setTextAreaValCopy] = useState<string>('');

	let [emails, setEmails] = useState([]);
	const [scheduleRes, setScheduleRes] = useState();

	const getEmails = (chips: any) => {
		let temp: any = [];
		chips?.map((chip: any) => {
			temp.push(chip.name);
		});
		return temp;
	};
	const [showChips, setShowChips]: any = useState(false);
	const submitEmail = async () => {
		if (chipValues?.trim()?.length == 0 && chipsList?.length == 0) {
			toaster.addToast({
				type: 'error',
				message: 'Please enter email to continue',
				timeout: 3000,
			});
		} else if (!chipValues && chipsList?.length == 0) {
			toaster.addToast({
				type: 'error',
				message: 'Please enter email to continue',
				timeout: 3000,
			});
		} else {
			if (values == 'CSV') {
				const headers: any = { 'access-token': userData['access-token'] };
				const request: any = {};
				request.linking_type = 0;
				request.format_key = values == 'CSV' ? 0 : 1;
				request.email =
					chipsList.length && chipValues.length
						? [...getEmails(chipsList), chipValues]
						: chipsList.length && !chipValues.length
						? [...getEmails(chipsList)]
						: [chipValues];
				request.dashboard_id = id;
				request.report_name = name;
				request.payload = { ...shareReportsRequest };

				const reqBody: any = {
					headers: headers,
					request: request,
				};
				loader.showLoader();
				await APIService.post(ShareReportsEndpoints.shareReports(reqBody))
					.then((res: any) => {
						toaster.addToast({
							type: 'success',
							message: 'Check your email',
							timeout: 3000,
						});
					})
					.catch((err: any) => {
						toaster.addToast({
							type: 'error',
							message: err.message,
							timeout: 3000,
						});
					})
					.finally(() => {
						loader.hideLoader();
					});
			} else {
				sendPDFbyEmail(
					chipsList.length && chipValues.length
						? [...getEmails(chipsList), chipValues]
						: chipsList.length && !chipValues.length
						? [...getEmails(chipsList)]
						: [chipValues]
				);
			}
		}
	};
	const setController = (name: any, value: any) => {
		setValue(name, value);
	};

	useEffect(() => {
		// getSchedules();
	}, []);

	const getSchedules = async () => {
		const headers: any = { 'access-token': userData['access-token'] };
		const request: any = {
			dashboard_id: id,
		};
		const reqBody: any = {
			headers: headers,
			request: request,
		};
		loader.showLoader();
		await APIService.post(ShareReportsEndpoints.getSchedule(reqBody))
			.then((res: any) => {
				if (res.data.id) setShowScheduling(true);
				setScheduleRes(res.data);
			})
			.catch((err: any) => {})
			.finally(() => {
				loader.hideLoader();
			});
	};

	const handleToggle = async (toggle: boolean) => {
		const headers: any = { 'access-token': userData['access-token'] };
		const request: any = { is_enabled: toggle ? 1 : 0 };
		const reqBody: any = {
			headers: headers,
			request: request,
		};
		loader.showLoader();
		await APIService.post(ShareReportsEndpoints.scheduleToggle(reqBody))
			.then((res: any) => {})
			.catch((err: any) => {})
			.finally(() => {
				loader.hideLoader();
			});
	};

	const sendPDFbyEmail = async (emails: any) => {
		let gridElement: any = document.getElementsByClassName(
			`${downloadPDFClassName}`
		)[0];
		loader.showLoader();
		drawDOM(gridElement, {
			paperSize: 'A4',
			scale: 0.3,
			repeatHeaders: false,
			forcePageBreak: '.page-break',
		})
			.then((group) => {
				return exportPDF(group);
			})
			.then((dataUri) => {
				const reqBody: any = {
					headers: { 'access-token': userData['access-token'] },
					request: {
						base64_string: dataUri.split(';base64,')[1],
						report_name: name,
						email: [...emails],
						linking_type: 0,
					},
				};
				APIService.post(ShareReportsEndpoints.uploadPDFtoServer(reqBody))
					.then((res: any) => {
						toaster.addToast({
							message: 'Email sent Successfully',
							type: 'success',
							timeout: 3000,
						});
					})
					.catch((err: any) => {
						toaster.addToast({
							type: 'error',
							message: err.message,
							timeout: 3000,
						});
					})
					.finally(() => {
						loader.hideLoader();
					});
			});
	};
	const handleChipDelete = (index: any, evt: any) => {
		chipsList.splice(index, 1);
		if (values === evt || textAreaValCopy === evt) {
			setChipValues('');
			setTextAreaValCopy('');
		}
		const findDelete = deletedAttributes.current?.findIndex(
			(deleteVal: any, deleteI: any) => deleteVal === evt
		);
		if (findDelete === -1) {
			deletedAttributes.current.push(evt);
		}
		setChipsList([...chipsList]);
		setCurrentChipIndex(-1);
	};
	const checkDuplicateChips = (evt: any) => {
		if (evt?.trim()) {
			return chipsList.find((val: any) => val.name === evt);
		}
		return false;
	};
	const regName =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	return (
		<Grid xs={12} container className='shareByEmail'>
			{/* -------radio buttons-------- */}
			<Grid
				xs={12}
				container
				display={'flex'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Grid xs={12} className='shareByEmail__radio'>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={values}
						style={{ display: 'flex', flexDirection: 'row', width: '30%' }}
						onChange={handleChange}
					>
						{includeCSV.includes(name) && (
							<Grid xs={6}>
								{' '}
								<FormControlLabel
									value='CSV'
									control={
										<Radio
											size='small'
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 28,
												},
											}}
										/>
									}
									label='CSV'
									labelPlacement='start'
								/>
							</Grid>
						)}
						<Grid xs={6}>
							<FormControlLabel
								className='className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"'
								value='PDF'
								control={
									<Radio
										size='small'
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 28,
											},
										}}
									/>
								}
								label='PDF'
								labelPlacement='start'
							/>
						</Grid>
					</RadioGroup>
				</Grid>
			</Grid>

			<Grid item xs={12} className='chip_Container' mb={1}>
				{chipsList.length ? (
					<div>
						{chipsList.map((i: any, index: number) => (
							<Chip
								color='primary'
								style={{
									display: index > 1 && showChips == false ? 'none' : '',
								}}
								label={i.name}
								onClick={
									i.isDefault
										? undefined
										: (ev: any) => {
												setCurrentChipIndex(index);
												setChipValues(ev.target.textContent);
												setTextAreaValCopy(ev.target.textContent);
										  }
								}
								variant={index === currentChipIndex ? 'outlined' : 'filled'}
								className='textarea_chips'
								onDelete={
									i.isDefault
										? undefined
										: (evt: any) => handleChipDelete(index, i.name)
								}
							/>
						))}
						{showChips == false && chipsList.length > 2 ? (
							<Link
								onClick={() => {
									setShowChips(true);
								}}
							>
								See more
							</Link>
						) : showChips == true && chipsList.length > 2 ? (
							<Link
								onClick={() => {
									setShowChips(false);
								}}
							>
								See less
							</Link>
						) : null}
					</div>
				) : (
					<Grid xs={6} position={'absolute'}>
						<Typography>Enter one or more email</Typography>
					</Grid>
				)}
			</Grid>

			<Grid container position={'relative'} marginTop={'1rem'}>
				<Link
					style={{
						cursor: 'pointer',
						position: 'absolute',
						right: '1rem',
						top: '-2rem',
					}}
					onClick={() => {
						setChipValues('');
						setChipsList([]);
						setValue('email', null);
						setEmails([]);
					}}
				>
					Clear all
				</Link>
				<Grid xs={12} style={{ borderRadius: '40px' }} key={Math.random()}>
					<Input
						id='email'
						name='email'
						autoFocus={textAreaValCopy.length}
						label={chipsList.length > 0 ? 'Enter one or more email' : ''}
						placeholder='Enter emails'
						// outerDivStyle={{ minWidth: '260px' }}
						value={chipValues}
						onChange={(event: any) => {
							if (
								event.target.value.trim() !== '' &&
								event.nativeEvent.inputType !== 'insertFromPaste'
							) {
								setTextAreaValCopy(event.target.value);
							}
							if (event.nativeEvent.inputType !== 'insertFromPaste') {
								if (!event.target.value.includes('\n')) {
									setChipValues(event.target.value);
								}
							}
							if (event.target.value.includes('\n')) {
								setChipValues('');
								setTextAreaValCopy('');
							}
						}}
						onKeyPress={(event: any) => {
							if (event.target.value.trim() !== '') {
								setTextAreaValCopy(event.target.value);
							}
							let eventVal = event.target.value;
							const x = event.keyCode;
							const y = event.code;

							// if (eventVal.length > 30) {
							// 	showExceedErrorMessage(true);
							// 	return;
							// }
							// if (eventVal.length < 31) {
							// 	showExceedErrorMessage(false);
							// }

							if (event.code == 'Enter') {
								setTextAreaValCopy('');

								// if (eventVal.includes(',')) {
								// 	eventVal = eventVal.substring(0, eventVal.length() - 1);
								// }

								if (eventVal.trim() == '') {
									return;
								}
								if (regName.test(eventVal) == false) {
									toaster.addToast({
										message: 'Please check the format of email',
										type: 'error',
										timeout: 2000,
									});
									return;
								}
								if (currentChipIndex > -1) {
									deletedAttributes.current.push(
										chipsList[currentChipIndex].name
									);

									if (
										!chipsList
											.map((i: any) => i.name.trim().toLowerCase())
											.includes(eventVal.trim().toLowerCase())
									) {
										chipsList[currentChipIndex].name = eventVal;
										setChipsList(chipsList);
										setCurrentChipIndex(-1);
									}
								} else {
									if (checkDuplicateChips(eventVal)) {
										setChipValues('');
										setTextAreaValCopy('');
										event.preventDefault();
										return;
									}
									let findAddI = addedAttributes.current?.findIndex(
										(addVal: any, addI: any) => addVal === eventVal
									);
									const findDelete = deletedAttributes.current?.findIndex(
										(deleteVal: any, deleteI: any) => deleteVal === eventVal
									);
									if (findDelete !== -1) {
										if (findAddI !== -1) {
											addedAttributes.current.splice(findAddI, 1);
										}
										const deleteList = deletedAttributes.current;
										deleteList.splice(findDelete, 1);
										deletedAttributes.current = deleteList;
										findAddI = 0;
									}
									if (findAddI === -1) {
										addedAttributes.current.push(eventVal);
									}
									if (
										!chipsList
											.map((i: any) => i.name.trim().toLowerCase())
											.includes(eventVal.trim().toLowerCase())
									) {
										chipsList.push({
											name: eventVal,
											isDefault: 0,
											isEnabled: 1,
										});
										setChipsList([...chipsList]);
									}
								}
								setChipValues('');
								setTextAreaValCopy('');
								event.preventDefault();
							}
						}}
						defaultValue=''
					/>
					{/* <Input
						name='email'
						placeholder='Enter one or more email'
						control={control}
					/> */}
				</Grid>
			</Grid>
			{/* -------------------------schedule report------------------- */}

			{/* 
				<Grid xs={12} className={'shareByEmail__switch'}>
					<SwitchButton
						selectedChecked={showScheduling}
						checked={showScheduling}
						onChange={(event: any) => {
							setShowScheduling(!showScheduling);
							handleToggle(event.target.checked);
						}}
					/>
					<Typography>Schedule reports</Typography>
				</Grid>
				{showScheduling && (
					<ScheduleReport
						controller={(name: any, value: any) => {
							setController(name, value);
						}}
						setValue={setValue}
						getValues={getValues}
						userData={userData}
						dashboard_id={id}
						loader={loader}
						scheduleRes={scheduleRes}
					/>
				)}

				 */}
			<Grid xs={12} textAlign={'center'}>
				<Button
					type='submit'
					onClick={() => {
						submitEmail();
					}}
					btnText='Send Report'
					style={{ width: '30%' }}
				/>
				<Button
					variant='outlined'
					type='button'
					btnText='Cancel'
					style={{ width: '30%' }}
					onClick={() => {
						closePopup();
					}}
				/>
			</Grid>
		</Grid>
	);
}

export default ShareThroughEmail;
